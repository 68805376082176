
  var editorScriptEntry = require('/home/builduser/work/2d815f4a486046ad/packages/image-palette-addons/src/editor.app.ts');

  
    if (editorScriptEntry.default) {
      module.exports = editorScriptEntry.default;
    } else {
      const { editorScriptBuilder } = require('@wix/bob-widget-services');
      const { editorReadyWrapper, createFlowAPIFabric, exportsWrapper } = require('@wix/yoshi-flow-editor/runtime/esm/editorScript.js');

      const biLogger = require('/home/builduser/work/2d815f4a486046ad/packages/image-palette-addons/target/generated/bi/createBILogger.ts').createOwnerBILogger;

      

  const { initI18n: initI18n } = require('@wix/yoshi-flow-editor/runtime/esm/i18next/init');


      

    var createExperiments = null;
    var createWidgetExperiments = null;
    

      
const { createHttpClient } = require('@wix/yoshi-flow-editor/runtime/esm/controller/httpClientProp');
    


      const sentryConfig = null;
      const experimentsConfig = {"scopes":["specs.components.editorXTheme"],"centralized":true};
      const translationsConfig = {"enabled":true,"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/2d815f4a486046ad/packages/image-palette-addons/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};
      const defaultTranslations = {"app.settings.empty.state.not.selected.title":"Select an image to generate a color palette. <0>Learn more</0>","app.settings.empty.state.unsupported.file.title":"You can only create palettes from images.","app.settings.empty.state.multiSelect.title":"Palettes can only be created from one image at a time.","app.settings.title":"Get Image Colors","app.settings.create.button":"Generate New Palette","app.settings.download.button":"Download palette","app.settings.undo.button":"Undo","app.settings.tooltip.copy":"Copy {hexColor}","app.settings.tooltip.copied":"Copied","app.settings.download.file.name":"Image Color Palette","app.tools.menu.label":"Get Image Colors"};

      const createFlowAPI = createFlowAPIFabric({
        sentryConfig,
        experimentsConfig,
        translationsConfig,
        defaultTranslations,
        biLogger,
        shouldUseEssentials: true,
        artifactId: 'image-palette-addons',
        appDefId: '4292d3bb-c3b2-4226-909e-0515ed40cf12',
        optionalDeps: {
          initI18n,
          createExperiments,
          createHttpClient
        },
        localeDistPath: 'assets/locales',
      });

      const useAPIsFromExports = typeof editorScriptEntry.exports === "function";

      function wrapEditorReady(eReady) {
        try {
          return editorReadyWrapper({
            createFlowAPI,
            editorReady: eReady,
            shouldSkipAPIOverrides: useAPIsFromExports,
          });
        } catch (e) {
          console.error(e);
          throw e;
        }
      }

      let wrappedEditorReady = wrapEditorReady(editorScriptEntry.editorReady);
      if (false) {
        const hot = require('@wix/yoshi-flow-editor/build/cjs/wrappers/editorScriptHot.js').hot;
        wrappedEditorReady = hot(module, wrappedEditorReady);
      }

      let builder = editorScriptBuilder();
      if (editorScriptEntry.editorReady) {
        builder = builder.withEditorReady(wrappedEditorReady);
      }

      if (editorScriptEntry.exports) {
        builder = builder.withExports(useAPIsFromExports ? exportsWrapper(editorScriptEntry.exports) : editorScriptEntry.exports);
      }

      if (editorScriptEntry.onEvent) {
        builder = builder.withEventHandler(editorScriptEntry.onEvent);
      }
      const controllerWidgetManifests = [];
      
      const userController_0 = require('/home/builduser/work/2d815f4a486046ad/packages/image-palette-addons/src/components/imagePalette/editor.controller.ts');

      const model_0 = null;

      const manifest_0 = userController_0 ? userController_0.default || userController_0 : {};
      if (!manifest_0.type) {
        manifest_0.type = "undefined";
      }
      if (!manifest_0.getExports && model_0) {
        manifest_0.getExports = () => ({"undefined": model_0.getExports()});
      }
      if (userController_0 && userController_0.getWidgetManifest) {
        controllerWidgetManifests.push({ widgetId: "undefined", getWidgetManifest: userController_0.getWidgetManifest});
      }

      builder = builder.withWidget(manifest_0);

      if (editorScriptEntry.getAppManifest || controllerWidgetManifests.length) {
        const customGetAppManifest = async (...params) => {
          const [{ appManifestBuilder }, editorSDK, contextParams] = params;
          const flowAPI = await createFlowAPI(editorSDK, contextParams);
          controllerWidgetManifests.forEach(({ widgetId, getWidgetManifest}) => {
            appManifestBuilder.configureWidget(widgetId, (builder) => getWidgetManifest(builder, editorSDK, flowAPI));
          });

          if (editorScriptEntry.getAppManifest) {
            return editorScriptEntry.getAppManifest(...params, flowAPI);
          }

          return appManifestBuilder.build();
        };
        builder = builder.withAppManifest(customGetAppManifest);
      }
      var result = builder.build();
      module.exports = result;
  }
  
