import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { getImageData } from './imageData';
import { AppSettings, AppSettingsListener } from '../types';

export function addListeners(
  editorSDK: FlowEditorSDK,
  setMethod: Function = (URL: string) => {},
  listeners: Array<AppSettingsListener>,
) {
  editorSDK?.addEventListener('componentDataChanged', async (event) => {
    const { compRef } = event.detail;
    const settingsData: AppSettings = await getImageData(editorSDK, compRef);
    listeners.forEach((cb: AppSettingsListener) => cb(settingsData));
    setMethod(settingsData);
  });
  editorSDK?.addEventListener('componentSelectionChanged', async (event) => {
    const { componentRefs } = event.detail;
    const settingsData: AppSettings = await getImageData(
      editorSDK,
      componentRefs,
    );
    listeners.forEach((cb: AppSettingsListener) => cb(settingsData));
    setMethod(settingsData);
  });
}

export function removeListeners(editorSDK: FlowEditorSDK) {
  editorSDK.removeEventListener('componentPropsChanged', async () => {});
  editorSDK.removeEventListener('componentDataChanged', async () => {});
  editorSDK.removeEventListener('componentSelectionChanged', async () => {});
}
