export const Algorithm_types = {
  MedianCut: 'MedianCut',
  KMeans: 'KMeans',
  Histogram: 'Histogram',
};
export const Elements_types = {
  Image: 'Image',
  ImageX: 'ImageX',
  Section: 'wysiwyg.viewer.components.ClassicSection',
  SectionX: 'responsive.components.Section',
  ImageXType: 'wixui.ImageX',
  ImageType: 'wysiwyg.viewer.components.WPhoto',
};
export const Plugin_name = 'image_colors';
