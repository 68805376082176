import { EditorReadyFn, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { getFrameUrl } from './utils/editor';
import { AppApi, AppSettings, AppSettingsListener } from './types';
import imagePaletteComp from './components/imagePalette/editor.controller';
import { addListeners } from './common/listeners';

// token is used on most SDK calls even though it has no real effect
const TOKEN = 'token';
const appSettingsListeners: Array<AppSettingsListener> = [];
let settings: AppSettings = {
  imageUrl: '',
  height: 0,
  width: 0,
  selectedImages: 0,
  selectedElements: 0,
  imageId: '',
};
let isClassicEditor: boolean = true;

export const editorReady: EditorReadyFn = async (
  editorSDK,
  token,
  { firstInstall },
  flowApi: FlowAPI,
) => {
  console.log(firstInstall ? 'FIRST RUN' : 'RUN');
  if (flowApi.environment.isEditorX) {
    isClassicEditor = false;
  }
  openSidePanel(editorSDK, flowApi);
  addListeners(
    editorSDK,
    (data: AppSettings) => {
      settings = { ...data };
    },
    appSettingsListeners,
  );
};
const openSidePanel = async (editorSdk: FlowEditorSDK, flowAPI: FlowAPI) => {
  const toolsMenuItemOptions = {
    title: flowAPI.translations.t('app.tools.menu.label'),
  };
  const toolsPanelOptions = {
    title: flowAPI.translations.t('app.settings.title'),
    width: 240,
    height: 300,
    url: getFrameUrl(imagePaletteComp.type, 'Settings'),
    initialPosition: { x: 100, y: 100 },
  };
  await editorSdk.editor.registerToolsPanel(
    '',
    toolsMenuItemOptions,
    toolsPanelOptions,
  );
};

const createPrivateAPI = (): AppApi => ({
  getSettings: () => settings,
  getIsClassicEditor: (): boolean => isClassicEditor,
  registerSettingsListener: (cb: AppSettingsListener) => {
    appSettingsListeners.push(cb);
    cb && cb(settings);
  },
});
const createPublicAPI = (editorSdk: FlowEditorSDK, flowApi: FlowAPI) => ({
  openSidePanel: () => openSidePanel(editorSdk, flowApi),
});

// exports is called before editorReady, executed once on editor load
export const exports = (editorSdk: FlowEditorSDK, flowApi: FlowAPI) => ({
  public: createPublicAPI(editorSdk, flowApi),
  private: createPrivateAPI(),
});
