import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { AppSettings } from '../types';
import { Elements_types } from './constants';

export const getImageData = async (
  editorSDK: FlowEditorSDK,
  selectedCompRef: ComponentRef | Array<ComponentRef>,
): Promise<AppSettings> => {
  const data: AppSettings = {
    imageUrl: '',
    height: 0,
    width: 0,
    selectedImages: 0,
    selectedElements: 0,
    imageId: '',
  };
  let filteredSelectedImages = [];
  const isMultiSelect =
    Array.isArray(selectedCompRef) && selectedCompRef.length > 1;

  if (isMultiSelect) {
    const mappedTypes = await Promise.all(
      selectedCompRef.map(async (comRef) => {
        const elementType = await editorSDK.components.getType('token', {
          componentRef: comRef,
        });
        return { compRef: comRef, type: elementType };
      }),
    );
    filteredSelectedImages = mappedTypes.filter(
      (element) =>
        element.type === Elements_types.ImageXType ||
        element.type === Elements_types.ImageType,
    );
    data.selectedImages = filteredSelectedImages.length;
    data.selectedElements =
      selectedCompRef.length - filteredSelectedImages.length;
    return data;
  } else {
    const compRef = Array.isArray(selectedCompRef)
      ? selectedCompRef[0]
      : selectedCompRef;
    if (compRef === undefined) {
      data.selectedImages = 0;
      data.selectedElements = 0;
    } else {
      const elementType = await editorSDK.components.getType('token', {
        componentRef: compRef,
      });
      if (
        elementType === Elements_types.Section ||
        elementType === Elements_types.SectionX
      ) {
        data.selectedImages = 0;
        data.selectedElements = 0;
      } else {
        const compStructure = await editorSDK.components.get('token', {
          componentRefs: compRef,
          properties: ['data', 'props'],
        });
        const imageData = compStructure.length === 1 && compStructure[0]?.data;
        const type = imageData?.type;

        if (type === Elements_types.ImageX) {
          const { uri, width, height } = imageData.image;
          data.imageUrl = uri;
          data.width = width || 0;
          data.height = height || 0;
          data.selectedImages = 1;
          data.selectedElements = 0;
          data.imageId = compStructure[0].componentRef.id;
        } else if (type === Elements_types.Image) {
          const { uri, width, height } = imageData;
          data.imageUrl = uri;
          data.width = width || 0;
          data.height = height || 0;
          data.selectedImages = 1;
          data.selectedElements = 0;
          data.imageId = compStructure[0].componentRef.id;
        } else {
          data.selectedImages = 0;
          data.selectedElements = 1;
        }
      }
    }
  }
  return data;
};
